import { createContext, ReactNode, useContext, useState } from "react";

type TickerContextType = {
  tickerData: any;
  setTickerData: (value: any) => any;
  subs: any;
  setSubs: (value: any) => any;
};

const init = {
  tickerData: [],
  setTickerData: () => null,
  subs: [],
  setSubs: () => null,
};

export const TickerContext = createContext<TickerContextType>(init);

export const TickerContextProvider = (props: { children: ReactNode }) => {
  const [tickerData, setTickerData] = useState<any>([]);
  const [subs, setSubs] = useState<any>([]);

  return (
    <TickerContext.Provider
      value={{ tickerData, setTickerData, subs, setSubs }}
    >
      {props.children}
    </TickerContext.Provider>
  );
};

export const useTicker = () => useContext(TickerContext);
