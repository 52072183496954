import { createContext, ReactNode, useContext, useState } from "react";

type LoadingContextType = {
  loading: boolean;
  setLoading: (value: any) => any;
};

const init = {
  loading: false,
  setLoading: () => null,
};

export const LoadingContext = createContext<LoadingContextType>(init);

export const LoadingContextProvider = (props: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {props.children}
    </LoadingContext.Provider>
  );
};

export const useGlobalLoading = () => useContext(LoadingContext);
