import { createContext, ReactNode, useContext, useState } from "react";

type DarkThemeContextType = {
  darkTheme: boolean;
  setDarkTheme: (value: any) => any;
};

const init = {
  darkTheme: false,
  setDarkTheme: () => null,
};

export const DarkThemeContext = createContext<DarkThemeContextType>(init);

export const DarkThemeContextProvider = (props: { children: ReactNode }) => {
  const [darkTheme, setDarkTheme] = useState(true);
  return (
    <DarkThemeContext.Provider value={{ darkTheme, setDarkTheme }}>
      {props.children}
    </DarkThemeContext.Provider>
  );
};

export const useDarkTheme = () => useContext(DarkThemeContext);
