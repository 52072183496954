import { createContext, ReactNode, useContext, useState } from "react";

type VfundsContextType = {
  funds: number;
  setFunds: (value: any) => any;
  status: boolean;
  setStatus: (value: any) => any;
  rechargeHistory: any;
  setRechargeHistory: (value: any) => any;
  profitLoss: any;
  setProfitLoss: (value: any) => any;
  totalDeployed: any;
  setTotalDeployed: (value: any) => any;
};

const init = {
  funds: 0.0,
  setFunds: () => null,
  status: false,
  setStatus: () => null,
  rechargeHistory: [],
  setRechargeHistory: () => null,
  profitLoss: [],
  setProfitLoss: () => null,
  totalDeployed: 0.0,
  setTotalDeployed: () => null,
};

export const VfundsContext = createContext<VfundsContextType>(init);

export const VfundsContextProvider = (props: { children: ReactNode }) => {
  const [funds, setFunds] = useState(0.0);
  const [totalDeployed, setTotalDeployed] = useState(0.0);
  const [status, setStatus] = useState(false);
  const [rechargeHistory, setRechargeHistory] = useState<any>([]);
  const [profitLoss, setProfitLoss] = useState<any>([]);

  return (
    <VfundsContext.Provider
      value={{
        funds,
        setFunds,
        totalDeployed,
        setTotalDeployed,
        status,
        setStatus,
        rechargeHistory,
        setRechargeHistory,
        profitLoss,
        setProfitLoss,
      }}
    >
      {props.children}
    </VfundsContext.Provider>
  );
};

export const useFunds = () => useContext(VfundsContext);
