import { createContext, ReactNode, useContext, useState } from "react";

type ModalContextType = {
  open: boolean;
  closeModal: () => void;
  openModal: (show: boolean, title: string, body: any) => void;
  size: string;
  setSize: (value: string) => void;
  body: any;
  setBody: (value: any) => any;
};

const init = {
  open: false,
  closeModal: () => null,
  openModal: () => "",
  size: "",
  setSize: () => "",
  body: null,
  setBody: () => null,
};

export const ModalContext = createContext<ModalContextType>(init);

export const ModalContextProvider = (props: { children: ReactNode }) => {
  const [open, setModalToggle] = useState<boolean>(false);
  const [size, setSize] = useState<string>("max-w-7xl");
  const [body, setBody] = useState<any>();

  const openModal = (show: boolean, size: string, body: any) => {
    setModalToggle(show);
    setSize(size);
    setBody(body);
  };

  const closeModal = () => {
    setModalToggle(false);
  };

  return (
    <ModalContext.Provider
      value={{ open, openModal, closeModal, size, setSize, body, setBody }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
